/* eslint-disable sonarjs/no-duplicate-string */
export default {
  'About us': 'Chi siamo',
  'Add new address': 'Aggiungi nuovo indirizzo',
  'Add to compare': 'Aggiungi per confrontare',
  'Add to Wishlist': 'Aggiungi alla lista dei desideri',
  'Additional Information': 'Informazioni aggiuntive',
  'Allow order notifications': 'Consenti notifiche ordini',
  Apply: 'Applica',
  'Attention!': 'Attenzione!',
  'Back to home': 'Torna alla home page',
  'Back to homepage': 'Torna alla homepage',
  'Billing address': 'Indirizzo di fatturazione',
  Brand: 'Marca',
  Cancel: 'Annulla',
  Categories: 'Categorie',
  'Change password your account': 'Se desideri modificare la password per accedere al tuo account, inserisci le seguenti informazioni',
  Change: 'Cambia',
  'Clear all': 'Cancella tutto',
  Color: 'Colore',
  Results: 'Risultati',
  'Commercial information': 'accetto di ricevere informazioni commerciali personalizzate dal marchio via e-mail',
  'Contact details updated': 'Mantieni aggiornati i tuoi indirizzi e dettagli di contatto.',
  'An error occurred while logging you in, are your credentials correct?':
    "Si è verificato un errore durante l'accesso, le credenziali sono corrette?",
  'Contact us': 'Contattaci',
  'PRODUCTS PURCHASED': 'PRODOTTI ACQUISTATI',
  'Continue to billing': 'Continua con la fatturazione',
  'Continue to payment': 'Continua al pagamento',
  'Continue to shipping': 'Continua con la spedizione',
  'Cookies Policy': 'Politica sui cookie',
  'GET INSPIRED BY OUR DIVO MAGAZINE': 'LASCIATI ISPIRARE DAL NOSTRO DIVO MAGAZINE',
  'Your Name': 'Il tuo nome',
  'Your Last Name': 'Il tuo cognome',
  'Your Address': 'Il tuo indirizzo',
  'TERMS AND CONDITIONS': 'TERMINI E CONDIZIONI',
  SHARE: 'CONDIVIDI',
  'Not available': 'Non disponibile',
  'Your subscription was successful!': 'Sei stato iscritto con successo!',
  "Sorry, we couldn't subscribe you to our newsletter. Please contact us at customercare@divoboutique.com!":
    'Ci dispiace, non siamo riusciti ad iscriverti alla newsletter! Per favore contattaci alla mail customercare@divoboutique.com',
  'You are already subscribed to the newsletter. for support contact us at customercare@divoboutique.com!':
    'Sei già iscritto alla newsletter. per assistenza contattaci a customercare@divoboutique.com!',
  'Invoice request': 'Richiedi fattura',
  'Save gift wrapping': 'Salva confezione regalo',
  'Save invoice request': 'Salva richiesta di fattura',
  "I'm a private": 'Sono un privato',
  "I'm a company": "Sono un'azienda",
  'ORDER ID': 'ID ORDINE',
  USER: 'CLIENTE',
  Company: 'Azienda/Società',
  'VAT number': 'Partita IVA',
  'TAX ID code': 'Codice fiscale',
  'SDI code': 'Codice SDI',
  'Create an account': 'Crea un account',
  'Customer Reviews': 'Recensioni dei clienti',
  'Customer service': 'Servizio clienti',
  EXPLORE: 'ESPLORA',
  'Request gift wrapping': 'Richiedi confezione regalo',
  'Write a message': 'Scrivi un messaggio',
  Delete: 'Elimina',
  Departments: 'Dipartimenti',
  Description: 'Dettagli',
  'Details and status orders':
    'Controlla i dettagli e lo stato dei tuoi ordini nel negozio online. Puoi anche annullare il tuo ordine o richiedere un reso.',
  Discount: 'Sconto',
  Done: 'Fatto',
  'Download all': 'Scarica tutto',
  Download: 'Scarica',
  Edit: 'Modifica',
  'Email address': 'Indirizzo e-mail',
  Empty: 'Sembra che tu non abbia ancora aggiunto alcun oggetto alla borsa. Inizia a fare acquisti per riempirlo.',
  'Enjoy your free account': 'Goditi questi vantaggi con il tuo account gratuito!',
  Feedback: 'Il tuo feedback è importante per noi. Facci sapere cosa potremmo migliorare',
  'Feel free to edit': 'In questa pagina puoi aggiornare i tuoi dati personali in qualsiasi momento.',
  Filters: 'Filtri',
  'Find out more': 'Scopri di più',
  'Forgot Password Modal Email': 'Email che stai usando per accedere:',
  'Forgot Password': 'Se non ricordi la password, puoi reimpostarla.',
  'Forgotten password?': 'Password dimenticata?',
  'Go back shopping': 'Torna a fare shopping',
  'Go back to shop': 'Torna al negozio',
  'Go back': 'Torna indietro',
  designers: 'designer',
  'SECURE PAYMENT METHODS WITH DIVO': 'METODI DI PAGAMENTI SICURI CON DIVO',
  'Order by December, 17th to receive your gifts in time for Christmas!': 'Ordina entro il 17 dicembre per ricevere il tuo ordine entro Natale!',
  'Extended return times!': 'Tempi di reso prolungati!',
  'YOUR ORDER': 'IL TUO ORDINE',
  'ORDER SUMMARY': 'RIEPILOGO ORDINE',
  VAT: 'IVA',
  Included: 'Inclusa',
  'within 1-3 working days': 'in 1-3 giorni lavorativi',
  'Do you have a coupon code?': 'Hai un codice sconto?',
  'Grand total': 'Totale complessivo',
  Guarantee: 'Garanzia',
  'Help & FAQs': 'Aiuto e FAQ',
  Help: 'Aiuto',
  hide: 'nascondere',
  Home: 'Casa',
  HOMEPAGE: 'HOMEPAGE',
  'Date of birth': 'Data di nascita',
  WOMAN: 'DONNA',
  MAN: 'UOMO',
  WOMEN: 'DONNA',
  MEN: 'UOMO',
  'Continue as guest': 'Continua come ospite',
  'We are sorry, the time for return has expired': 'Ci dispiace, il tempo per effettuare il reso è scaduto',
  'Order must be shipped to request a return': "L'ordine deve essere spedito per poter effettuare il reso",
  'USE THE DHL WAYBILL YOU FOUND IN THE PARCEL AND DELIVER IT TO THE NEAREST DHL POINT:':
    'UTILIZZA LA LETTERA DI VETTURA DHL CHE HAI TROVATO NEL PACCO E CONSEGNALO AL DHL POINT PIÙ VICINO:',
  'I agree to': 'Accetto',
  'I confirm subscription': "Confermo l'iscrizione",
  'Info after order':
    "Puoi accedere al tuo account utilizzando e-mail e password definite in precedenza. Sul tuo account puoi modificare i dati del tuo profilo, controllare la cronologia delle transazioni, modificare l'iscrizione alla newsletter.",
  Instruction1: 'Prenditi cura di me',
  Instruction2: 'Solo qui per le istruzioni di cura?',
  Instruction3: 'Sì, lo pensavamo',
  'It was not possible to request a new password, please check the entered email address.':
    "Non è stato possibile richiedere una nuova password, si prega di controllare l'indirizzo email inserito.",
  Item: 'Articolo',
  Items: 'Articoli',
  Kidswear: 'Abbigliamento per bambini',
  'Let’s start now – we’ll help you': 'Iniziamo ora, ti aiuteremo.',
  'Log into your account': 'Accedi al tuo account',
  'login in to your account': 'accedi al tuo account',
  Login: 'Accedi',
  'Divo Boutique ⋄ Fashion and Luxury Official Online Store': 'Divo Boutique ⋄ Moda e Lusso Store Online Ufficiale',
  'Enter Divo Boutique online store and discover men, women and kids’ collections designed by the most exclusive fashion brands. Enjoy your shopping in the official shop.':
    'Entra nel mondo Divo Boutique e scopri le collezioni uomo, donna e bambino firmate dai migliori designer della moda. Acquista online nello store ufficiale.',
  'Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.':
    'Sembra che tu non abbia ancora aggiunto alcun oggetto alla borsa. Inizia a fare acquisti per riempirlo.',
  'Shop now': 'Acquista ora',
  'Manage addresses': 'Salva i tuoi indirizzi più usati (abitazione, posto di lavoro…) in modo da poterli selezionare comodamente ad ogni ordine.',
  'Manage billing addresses':
    "Gestisci tutti gli indirizzi di fatturazione che desideri (posto di lavoro, indirizzo di casa...) In questo modo non dovrai inserire manualmente l'indirizzo di fatturazione con ogni ordine.",
  'Manage shipping addresses':
    "Gestisci tutti gli indirizzi di spedizione che desideri (posto di lavoro, indirizzo di casa...) In questo modo non dovrai inserire manualmente l'indirizzo di spedizione con ogni ordine.",
  'Match it with': 'Abbinalo a',
  'Men fashion': 'Moda uomo',
  'My Cart': 'Il mio carrello',
  'No account': 'Non hai ancora un account?',
  'On Hold': 'Bloccato/Annullato',
  'or fill the details below': 'o compila i dettagli qui sotto',
  or: 'oppure',
  'Order No.': "Numero d'ordine",
  'Order summary': "Riepilogo dell'ordine",
  'Other products you might like': 'Altri prodotti che potrebbero piacerti',
  'Password Changed': 'Password modificata correttamente. Ora puoi tornare alla home page e accedere.',
  'Pay for order': 'Paga per ordine',
  'Payment & Delivery': 'Pagamento e consegna',
  'Payment method': 'Metodo di pagamento',
  'Payment methods': 'Metodi di pagamento',
  Payment: 'Pagamento',
  'Personal details': 'Dati personali',
  'Please type your current password to change your email address.': 'Digita la password corrente per modificare il tuo indirizzo email.',
  Price: 'Prezzo',
  'Privacy Policy': 'Informativa sulla privacy',
  Privacy: 'Privacy',
  'Product suggestions': 'Suggerimenti sui prodotti',
  Product: 'Prodotto',
  'Products found': 'Prodotti trovati',
  Products: 'Prodotti',
  'Purchase terms': 'Condizioni di acquisto',
  'Quality in the details': 'Qualità nei dettagli',
  Quantity: 'Quantità',
  'Read all reviews': 'Leggi tutte le recensioni',
  'Read and understand': "Ho letto e capito l'",
  'Read reviews': 'Leggi le recensioni',
  'Register today': 'Registrati oggi',
  'Register here': 'Registrati qui',
  Register: 'Crea un account (traccia l’ordine, gestisci i resi, accesso saldi privati)',
  'Remove from Wishlist': 'Rimuovi dalla lista dei desideri',
  'Reset Password': 'Reimposta password',
  'Review my order': 'Rivedi il mio ordine',
  'Same as shipping address': "Uguale all'indirizzo di spedizione",
  'Save changes': 'Salva modifiche',
  'Save for later': 'Salva per dopo',
  'Save Password': 'Salva password',
  'Search for items': 'Cerca articoli',
  'Search results': 'Risultati della ricerca',
  'Sections that interest you': 'Sezioni che ti interessano',
  'See all results': 'Vedi tutti i risultati',
  'Select payment method': 'Seleziona metodo di pagamento',
  'Select shipping method': 'Seleziona il metodo di spedizione',
  'Send my feedback': 'Invia il mio feedback',
  'Set up newsletter': 'Spunta la casella qui sotto per essere sempre aggiornato sulle nostre novità e promozioni.',
  'Share your look': 'Condividi il tuo look',
  'Shipping address': 'Indirizzo di spedizione',
  'Shipping details': 'Dettagli di spedizione',
  'Shipping method': 'Metodo di spedizione',
  'show more': 'mostra di più',
  'Show on page': 'Mostra sulla pagina',
  'Sign in': 'Accedi',
  'Sort by': 'Ordina per',
  'Sort: Default': 'Predefinito',
  'Sort: Name A-Z': 'Nome A-Z',
  'Sort: Name Z-A': 'Nome Z-A',
  'Sort: Price from high to low': 'Prezzo da alto a basso',
  'Sort: Price from low to high': 'Prezzo da basso ad alto',
  'Sort: New arrivals': 'Nuovi Arrivi',
  'Start shopping': 'Inizia a fare shopping',
  'Subscribe to newsletter': 'Iscriviti alla newsletter',
  Subscribe: 'Iscriviti',
  Subtotal: 'Subtotale',
  'Successful placed order':
    "Hai effettuato con successo l'ordine. Puoi controllare lo stato del tuo ordine utilizzando la nostra funzione di stato della consegna. Riceverai un'e-mail di conferma dell'ordine con i dettagli del tuo ordine e un link per monitorarne l'avanzamento.",
  'Thank You Inbox': 'Se il messaggio non arriva nella tua casella di posta, prova un altro indirizzo email che potresti aver usato per registrarti.',
  'You will receive an email with a link to reset your password': "Riceverai un'email con il link per reimpostare la password",
  'Total items': 'Totale articoli',
  'Total price': 'Prezzo totale',
  Total: 'TOTALE COMPLESSIVO',
  'Update password': 'Aggiorna password',
  'Update personal data': 'Aggiorna i miei dati',
  'Use your personal data':
    "Attribuiamo grande importanza alle questioni relative alla privacy e ci impegniamo a proteggere i dati personali dei nostri utenti. Scopri di più su come ci prendiamo cura e utilizziamo i tuoi dati personali nell' ",
  'User Account': 'Account utente',
  'View details': 'Vedi Dettagli',
  View: 'Vista',
  'Who we are': 'Chi siamo',
  'Women fashion': 'Moda donna',
  'You can unsubscribe at any time': "Puoi annullare l'iscrizione in qualsiasi momento",
  'You currently have no orders': 'Al momento non hai ordini',
  'You haven’t searched for items yet': 'Non hai ancora cercato oggetti.',
  'Your bag is empty': 'La tua borsa è vuota',
  'Your current email address is': 'Il tuo indirizzo email attuale è',
  forgotPasswordConfirmation: "Grazie! Riceverai un'email con il link per reimpostare la password all'indirizzo {0}",
  subscribeToNewsletterModalContent:
    'Dopo esserti iscritto alla newsletter, riceverai offerte speciali e messaggi da VSF via e-mail. Non venderemo o distribuiremo la tua e-mail a terzi in nessun momento. Si prega di consultare il nostro {0}.',
  'Do you want to contact us?': 'Vuoi contattarci?',
  'Fill out the form below!': 'Compila il form qui sotto!',
  'Come visit': 'Vieni a trovarci',
  'In our stores, as well as online, we welcome women from different generations. From mothers to their young daughters.':
    'Nei nostri negozi, così come online, accogliamo donne di generazioni diverse. Dalle mamme alle loro giovani figlie.',
  'Follow us and stay updated': 'Seguici e rimani aggiornato',
  'FOLLOW US ON INSTAGRAM': 'SEGUICI SU INSTAGRAM',
  'Required field': 'Campo obbligatorio',
  'You already have an account with this email. Log in now': 'Hai già un account con questa mail. Accedi ora',
  Name: 'Nome',
  'Last name': 'Cognome',
  'Phone Number': 'N. Telefono',
  Message: 'Messaggio',
  'Send Message': 'Invia messaggio',
  Type: 'Tipologia',
  'La nostra storia': 'La nostra storia',
  'Siamo una piccola azienda di famiglia: abbiamo forti legami con il tessuto produttivo del nostro paese ma ispirazioni molto internazionali.':
    'Siamo una piccola azienda di famiglia: abbiamo forti legami con il tessuto produttivo del nostro paese ma ispirazioni molto internazionali.',
  'Fatto con amore': 'Fatto con amore',
  'in Italia.': 'in Italia.',
  'Key years of our company which has moved from a production for third parties to the production and direct sale of our brand.':
    'Anni chiave della nostra azienda che si è indirizzata da una produzione per conto terzi alla produzione e alla vendita diretta di un nostro marchio.',
  'The workshop moves to a colonial house in the center of the town. Current headquarters of our company in San Bonifacio.':
    'Il laboratorio si sposta in una casa coloniale al centro del paese. Attuale sede della nostra azienda a San Bonifacio.',
  'Laboratory born in the province of Vicenza, starting to work for big fashion brands, producing quality raincoats and shrugs.':
    'Laboratorio nato in provincia di Vicenza, iniziando a lavorare per grandi marchi della moda, producendo impermeabili e coprispalle di qualità.',
  'Our product is the result of a lot of love for our job and we believe in the true Made in Italy, understood as the packaging of clothing made with precious raw materials: the choice of the fabrics we use is made taking into account the origin of the same, with a preference for materials whose printing and finishing are made in Italy in compliance with sustainability regulations.':
    'Il nostro prodotto è frutto di tanto amore per il nostro mestiere e crediamo nel vero Made in Italy, inteso come confezione di capi di abbigliamento realizzati con materie prime pregiate: la scelta dei tessuti che utilizziamo è fatta tenendo conto della provenienza degli stessi, con una predilezione per i materiali la cui stampa e finissaggio sono realizzati in Italia nel rispetto delle normative di sostenibilità.',
  'Find out shipping costs': 'Scopri costi di spedizione',
  'Worldwide delivery in 2/7 working days.': 'Consegna in tutto il mondo in 2/7 giorni lavorativi.',
  'Thank you for your order!': 'Grazie per il tuo ordine!',
  'Your Purchase': 'Il tuo acquisto',
  'Your Account': 'Il tuo account',
  'You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.':
    "Hai effettuato correttamente l'ordine. Puoi controllare lo stato del tuo ordine utilizzando la nostra funzione di stato di consegna. Riceverai un'e-mail di conferma dell'ordine con i dettagli del tuo ordine e un link per seguirne lo stato di avanzamento.",
  'You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, of transactions, edit subscription to newsletter.':
    "Puoi accedere al tuo account utilizzando l'e-mail e la password definite in precedenza. Sul tuo account puoi modificare i dati del tuo profilo, delle transazioni, modificare l'iscrizione alla newsletter.",
  Contacts: 'Contatti',
  'Back to home page': 'Torna alla home page',
  'Description product': 'Descrizione prodotto',
  'Let yourself be guided by our advice': 'Lasciati guidare dai nostri consigli',
  'Here is a list of related garments': 'Ecco un elenco di capi correlati',
  Carrello: 'Carrello',
  Si: 'Sì',
  Annulla: 'Annulla',
  'Sei sicuro di voler rimuovere questo articolo dal carrello?': 'Sei sicuro di voler rimuovere questo articolo dal carrello?',
  'Acquista ': 'Acquista ',
  'Torna allo shopping': 'Torna allo shopping',
  "It looks like you haven't added any items to the bag yet. Start shopping to fill it out.":
    'Sembra che tu non abbia ancora aggiunto alcun articolo al carrello.',
  Sizes: 'Taglia',
  'Size specifications': 'Specifiche delle dimensioni',
  'Do you want to be notified if the sizes will be available again?': 'Vuoi essere avvisato se le taglie saranno nuovamente disponibili?',
  'Add to cart': 'Aggiungi al carrello',
  Story: 'Storia',
  'you discover': 'scopri',
  'Made with love in Italy': 'Fatto con amore in Italia',
  'Our garments are made with precious raw materials and carefully chosen paying attention to their origin in compliance with the rules on sustainability.':
    'I nostri capi sono realizzati con materie prime pregiate e attentamente scelte facendo attenzione alla loro provenenienza nel rispetto delle regole sulla sostenibilità.',
  'Discover ours': 'Scopri la nostra',
  'Discover our wool': 'Scopri la nostra lana',
  'Discover the new winter sweaters': 'Scopri i nuovi maglioni invernali',
  'The novelty of the moment!': 'La novità del momento!',
  'Our recommendations': 'I nostri consigli',
  Size: 'Taglia italiana',
  'Sort by relevance': 'Ordina per rilevanza',
  'Apply filters': 'Applica filtri',
  'Something went wrong during form submission. Please try again later':
    "Qualcosa è andato storto durante l'invio del modulo. Per favore riprova più tardi",
  Billing: 'Fatturazione',
  'First name': 'Nome',
  'Street name': 'Via',
  'House/Apartment number': 'Numero civico',
  City: 'Città',
  'Zip-code': 'Cap',
  'Phone number': 'Numero di telefono',
  'Please select a country first': 'Seleziona prima un paese',
  'This field is required': 'Questo campo è obbligatorio',
  'Create an account on the store': 'Crea un account',
  'Login on the store': 'Ho già un account',
  Safety: 'Sicurezza',
  'It carefully packaged with a personal touch': 'Imballato con cura con un tocco personale',
  'Easy shipping': 'Spedizione facile',
  'You’ll receive dispatch confirmation and an arrival date': 'Riceverai la conferma della spedizione e una data di arrivo',
  'Changed your mind?': 'Hai cambiato idea?',
  'Rest assured, we offer free returns within 30 days': 'Stai tranquillo, offriamo resi gratuiti entro 30 giorni',
  'Copy address data from shipping': "Copia i dati dell'indirizzo dalla spedizione",
  Search: 'Ricerca',
  'State/Province': 'Stato/Provincia',
  'The field should have at least 2 characters': 'Il campo deve contenere almeno 2 caratteri',
  Monday: 'Lunedì',
  'Tuesday - Saturday': 'Da martedì a Sabato',
  'Tuesday - Friday': 'Da martedì a Venerdì',
  Saturday: 'Sabato',
  Sunday: 'Domenica',
  'Go to cart': 'Vai al carrello',
  'Back to cart': 'Torna al carrello',
  'ORDER NUMBER ': 'NUMERO ORDINE ',
  'ORDER COMPLETED!': 'ORDINE COMPLETATO!',
  'THANK YOU, WE HAVE RECEIVED YOUR ORDER REQUEST': 'GRAZIE, ABBIAMO RICEVUTO LA TUA RICHIESTA D’ORDINE',
  'YOU WILL RECEIVE AN ORDER CONFIRMATION EMAIL TO YOUR EMAIL ADDRESS SHORTLY. YOU CAN SEE THE STATUS OF YOUR ORDER ON THE ':
    'RICEVERAI A BREVE UNA EMAIL DI CONFERMA D’ORDINE AL TUO INDIRIZZO EMAIL. PUOI VEDERE LO STATO DEL TUO ORDINE NELLA ',
  'TRACKING PAGE': 'PAGINA DI TRACKING',
  'CONTINUE TO SHOPPING': 'CONTINUA LO SHOPPING',
  'Do you have a promo code?': 'Hai un codice sconto?',
  'Select your Country': 'Seleziona il Paese',
  'Remember me': 'Ricordami',
  'Sign Up for Newsletter': 'Iscriviti alla newsletter',
  'I want to create an account': 'Voglio creare un account',
  'Your email': 'Indirizzo Email',
  'Your e-mail': 'Email',
  'First Name': 'Nome',
  'Personal data': 'Dati personali',
  'Password change': 'Cambio password',
  'My Account': 'Il mio account',
  'Personal Details': 'Informazioni account',
  'My profile': 'Il mio profilo',
  'Addresses details': 'I miei indirizzi',
  'My newsletter': 'La mia newsletter',
  'Order details': 'Dettagli degli ordini',
  'Log out': 'DISCONNETTERSI',
  'Current Password': 'Password Attuale',
  'New Password': 'Nuova Password',
  and: 'e',
  'and the': 'e la',
  'All Orders': 'Tutti gli ordini',
  'Order ID': 'Ordine',
  Date: 'Data',
  Status: 'Stato',
  Amount: 'Totale',
  'Payment date': 'Data',
  'Payment Date': 'Data',
  'Order Date': 'Data di ordine',
  'My orders': 'I miei ordini ',
  'Order list': 'Lista Ordini',
  'Insert your email address': 'Inserisci il tuo indirizzo email',
  'Subscribe to the newsletter': 'Iscriviti alla newsletter',
  'Please enter a valid email address.': 'Si prega di inserire un indirizzo email valido.',
  'Contacts Us': 'Contattaci',
  Services: 'Servizi',
  Shops: 'Negozi',
  Social: 'Social',
  'Add to favourites': 'Aggiungi ai preferiti',
  Share: 'Condividi',
  Cart: 'Carrello',
  'Order history': 'Cronologia ordini',
  'Add the address': "Aggiungi l'indirizzo",
  'Update the address': "Aggiorna l'indirizzo",
  'Set as default shipping': 'Imposta come spedizione predefinita',
  'Set as default billing': 'Imposta come fatturazione predefinita',
  'The field should have at least {length} characters': 'Il campo deve contenere almeno {length} caratteri',
  "Passwords don't match": 'Le password non corrispondono',
  'Passwords do not match': 'Le password non corrispondono',
  'The user account data was successfully updated!': "I dati dell'account utente sono stati aggiornati correttamente!",
  'This feature is not implemented yet! Please take a look at': "Questa funzione non è ancora implementata! Si prega di dare un'occhiata",
  'for our Roadmap!': 'per la nostra tabella di marcia!',
  Returns: 'Ritorna',
  'Repeat Password': 'Ripeti password',
  'Last Name': 'Cognome',
  'Invalid email': 'E-mail non valida',
  'Use this address as my default one.': 'Usa questo indirizzo come quello predefinito.',
  'Default Shipping Address': 'Indirizzo di spedizione predefinito',
  'Default Billing Address': 'Indirizzo di fatturazione',
  'Shipping and returns': 'Spedizione e reso',
  'Select a shipping address': 'Seleziona un indirizzo di spedizione',
  'Use this address': 'Utilizza questo indirizzo',
  'Customer care: +39 045 7614582': 'Servizio clienti: +39 045 7614582',
  'Usually arrives in 5-13 business days. A shipping timeline specific to your destination can be viewed in Checkout.':
    'Di solito arriva in 5-13 giorni lavorativi. Una sequenza temporale di spedizione specifica per la tua destinazione può essere visualizzata in Checkout.',
  'Your cart is empty': 'Il tuo carrello è vuoto',
  'Looks like you haven’t added any items to the cart yet. Start shopping to fill it in.':
    'Sembra che tu non abbia ancora aggiunto alcun articolo al carrello. Inizia a fare acquisti per compilarlo.',
  Password: 'Password',
  'The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)':
    'La password deve essere lunga almeno 8 caratteri e deve contenere almeno: 1 lettera maiuscola o minuscola, 1 numero o un carattere speciale (Es. , . _ & ? ecc.)',
  'Our story': 'La nostra storia',
  'Your shopping cart is empty': 'Il tuo carrello è vuoto',
  'Search Results': 'Risultati di ricerca',
  'Discover More': 'Scopri di più',
  'Subscribe to our newsletter': 'Iscriviti alla nostra newsletter',
  'Order By': 'Ordina per',
  'Visit us': 'Vieni a trovarci',
  'Need Help?': 'Serve Aiuto?',
  'Fill out the form below and we will reach back to you!': 'Compila il form qui sotto, ti contatteremo al più presto!',
  'OUR STORY': 'LA NOSTRA STORIA',
  'We are a small family-run business with a strong bond with our country.':
    'Siamo una piccola azienda di famiglia con forti legami con il tessuto produttivo del nostro paese.',
  'The page you were looking for cannot be found': 'La pagina che stai cercando non esiste',
  'If you typed the URL directly, please make sure the spelling is correct. If you clicked on a link to get here, the link is outdated.':
    "Se hai digitato direttamente l'URL, assicurati che l'ortografia sia corretta. Se hai cliccato su un link per arrivare qui, il link è obsoleto.",
  'You can also click the following links to get you back on track!': 'Puoi anche fare clic sui seguenti collegamenti per rimetterti in carreggiata!',
  'Your cart': 'Il tuo carrello',
  'GO TO CHECKOUT': 'VAI AL CHECKOUT',
  'Go to checkout': 'Vai al checkout',
  Checkout: 'Checkout',
  'Bank transfer': 'Bonifico',
  'Cash on delivery': 'Contrassegno',
  Check: 'Assegno',
  'No products were found': 'Non sono stati trovati prodotti',
  'Not found': 'Non trovato',
  'Wrong user credentials': 'Credenziali utente errate',
  'Email was not found, not available or token is expired. Please request a new password.':
    'Email non trovata, non disponibile o token scaduto. Per favore richiedi una nuova password.',
  'successfully removed from your cart': 'è stato rimosso con successo dal carrello.',
  'Ship to an address': 'Spedisci ad un indirizzo',
  'Pickup from UPS Access Point™ location': 'Ricevi presso un UPS Access Point™',
  Notes: 'Note',
  'Unable to select payment method, please try again later': 'Non è possibile selezionare il metodo di pagamento, si prega di riprovare più tardi',
  Continue: 'Continua',
  'MY ITEMS': 'I MIEI ARTICOLI',
  'PICK UP IN STORE': 'RITIRO IN NEGOZIO',
  'Invoice request saved successfully.': 'Richiesta di fattura salvata con successo.',
  'Gift wrapping saved successfully.': 'Confezione regalo salvata con successo.',
  'Hello, ': 'Ciao, ',
  'DELIVERY IN 1-3 WORKING DAYS': 'CONSEGNA IN 1-3 GIORNI LAVORATIVI',
  Street: 'Via',
  'Street number': 'Numero Civico',
  Postcode: 'CAP',
  Region: 'Regione',
  'Save current address': 'Salva indirizzo',
  'Please choose another shipping method in order to use a coupon.': 'Non puoi usare coupon se scegli ritiro in negozio.',
  'Use another card': "Usa un'altra carta",
  'Use another account': 'Usa un altro account',
  'Unable to process payment information, please try again':
    'Non è stato possibile verificare le informazioni per il pagamento, si prega di riprovare',
  'Card type not supported. Please try with another card enabled for 3D Secure':
    'Carta non supportata. Si prega di riprovare con una carta abilitata per 3D Secure',
  'The size and color must be selected before adding a product to the cart!':
    'La taglia e il colore devono essere selezionati prima di aggiungere un prodotto al carrello!',
  'Out of stock': 'Non disponibile',
  'Italian size': 'Taglia italiana',
  Italian: 'Italiano',
  English: 'Inglese',
  'Size guide': 'Guida alle taglie',
  'Not the size you want?': "Non c'è la taglia che desideri?",
  'Notify me if back in stock': 'Vuoi essere avvisato se questo articolo tornerà disponibile?',
  Details: 'Descrizione',
  Shipping: 'Spedizione',
  'Shipments are made by UPS courier and are normally dispatched within 48 hours. Only in exceptional cases (for example during the sales period) it may take 3-4 business days to prepare the shipment.':
    'Le spedizioni sono effettuate con corriere UPS e vengono normalmente evase entro 48 ore. Solo in casi eccezionali (per esempio durante il periodo dei saldi) possono essere necessari 3-4 giorni lavorativi per preparare la spedizione.',
  'On average, delivery in Italy takes place 24-48 hours after the shipment, whilst it may take 2-6 business days for Europe and other countries.':
    'Per le consegne in Italia la spedizione è gratuita per importi pari o superiori a 100€, mentre per importi inferiori è previsto un contributo di 5€.La consegna avviene mediamente 24-48 ore dopo la spedizione dell’ordine.',
  'Shipping status can be verified at any time by using the tracking number provided via mail.':
    'Con la mail di conferma dell’ordine verrà fornito un codice di tracciamento per poter seguire la spedizione.',
  'Would you like to discover all about payments and returns? Have a look here. ("here" linkato a pagina customer care)':
    'Vuoi dare un’occhiata ai metodi di pagamento accettati o alla policy relativa a cambi e resi? Puoi trovare tutto qui. ("qui" linkato a pagina customer care)',
  'Get inspired We think you might love these': 'Lasciati ispirare Pensiamo che questi capi potrebbero piacerti',
  'Sign up for our newsletter': 'Iscriviti alla nostra newsletter',
  'By clicking “subscribe”, I agree that my information can be used for marketing segmentation.':
    'Cliccando su “Iscriviti”, acconsento che le mie informazioni vengano utilizzate per profilazione marketing.',
  'Re-Password': 'Re-Password',
  'Get inspired': 'Lasciati ispirare',
  'We think you might love these': 'Pensiamo che questi capi potrebbero piacerti',
  'Confirmation request has been sent.': 'La richiesta di conferma è stata inviata.',
  'The order has been paid with a gift card': "L'ordine è stato pagato con una gift card",
  'Enter gift card code': 'Inserisci il codice della gift card',
  'Paid with gift card': 'Pagato con gift card',
  'Gift card applied successfully.': 'Gift card applicata correttamente.',
  'Unable to apply gift card': 'Impossibile applicare la gift card',
  'Unable to update password': 'Impossibile aggiornare la password',
  'An error occurred': 'Si è verificato un errore',
  'please retry. If the problem persist, you can': 'ti invitiamo a riprovare. Se il problema persiste, puoi',
  'create a new cart': 'creare un nuovo carrello',
  'Your cart is outdated and must be reloaded': 'Il tuo carrello non è più valido e deve essere ricaricato',
  Reload: 'Ricarica',
  'My billing and shipping address are the same': "Usa l'indirizzo di spedizione anche per la fatturazione",
  'You reached the maximum saleable quantity': 'Hai raggiunto la massima quantità acquistabile',
  'Your request was submitted successfully; we will get back to you as soon as possible.':
    'La tua richiesta è stata inviata con successo; sarai ricontattato appena possibile.',
  'Request sent': 'Richiesta inviata',
  'Reset filters': 'Azzera filtri',
  'This field cannot contain a semicolon (;)': 'Questo campo non può contenere un punto e virgola (;)',
  'By subscribing you accept our': 'Iscrivendoti accetti i nostri',
  'and our policy about the': 'e la nostra politica sulla',
  'Copyright Text': '© 2023 DIVO SRL - VIA DI BIENTINA, 64 - 56020 SANTA MARIA A MONTE, PISA - ITALIA REA PI 164343 C.F. P. IVA 01901770501',
  'Country and language': 'Paese e lingua',
  'confirm changes': 'conferma modifiche',
  'select country and language': 'seleziona paese e lingua',
  Country: 'Paese',
  Language: 'Lingua',
  english: 'inglese',
  italian: 'italiano',
  italy: 'italia',
  'select country': 'seleziona il paese',
  'select language': 'seleziona la lingua',
  'Insert your email here': 'Inserisci la tua email qui',
  'Get now 15% discount to apply to your order': 'Ricevi subito il 15% di sconto da applicare al tuo ordine',
  'The country entered in the address is not available in the current market':
    "Il paese inserito nell'indirizzo non è disponibile nel mercato corrente",
  'Unable to set address': 'Unable to set address',
  'View All': 'Vedi tutti',
  'You seem to be connected from ': 'Sembra tu sia connesso in ',
  'Do you wish to switch country?': 'Vuoi cambiare paese?',
  'free shipping': 'Spedizione gratuita',
  'Free shipping': 'Spedizione gratuita',
  'FREE SHIPPING': 'Spedizione gratuita',
  Italy: 'Italia',
  'Accept suggestion': 'Accetta suggerimento',
  'Choose manually': 'Scegli manualmente',
  'DHL EXPRESS SHIPPING WITHIN 1-3 BUSINESS DAYS': 'SPEDIZIONE DHL EXPRESS ENTRO 1-3 GIORNI',
  'DHL Express delivery within': 'Consegna DHL Express entro',
  days: 'giorni',
  'BUY WITH': 'ACQUISTA CON',
  Surname: 'Cognome',
  Phone: 'Telefono',
  'Choose an open position': 'Scegli una posizione aperta',
  'Your application was sent': 'La tua candidatura è stata inviata',
  'There was an error while sending your application, please contact us at customercare@divoboutique.com':
    "Si è verificato un errore durante l'invio della tua candidatura, contattaci alla mail customercare@divoboutique.com",
  'HOW TO WEAR IT': 'COME ABBINARLO',
  'MORE FOR YOUR LOOK': 'ALTRO PER IL TUO LOOK',
  'RECENTLY VIEWED': 'VISTI DI RECENTE',
  Remove: 'RIMUOVI',
  'MY CART': 'IL MIO CARRELLO',
  'ON THIS PAGE YOU CAN UPDATE YOUR PERSONAL DATA AT ANY TIME.': 'IN QUESTA PAGINA PUOI AGGIORNARE I TUOI DATI PERSONALI IN QUALSIASI MOMENTO.',
  'YOUR PRIVACY IS IMPORTANT! FIND OUT HOW WE PROTECT YOUR PERSONAL DATA IN OUR PRIVACY':
    'LA TUA PRIVACY È IMPORTANTE! SCOPRI COME PROTEGGIAMO I TUOI DATI PERSONALI NELLA NOSTRA INFORMATIVA SULL’INFORMATIVA SULLA',
  POLICY: 'PRIVACY',
  BACKWARDS: 'INDIETRO',
  BACK: 'INDIETRO',
  'MAKE A RETURN': 'EFFETTUA UN RESO',
  'MAKE A CHANGE': 'EFFETTUA UN CAMBIO',
  'SELECT PRODUCT TO BE CHANGED': "SELEZIONA L'ARTICOLO DA CAMBIARE",
  SELECT: 'SELEZIONA',
  'THERE ARE NO PRODUCTS AVAILABLE FOR CHANGE': 'NON CI SONO PRODOTTI DISPONIBILI PER IL CAMBIO',
  SIZE: 'TAGLIA',
  'PAYMENT METHOD': 'METODO DI PAGAMENTO',
  'CREDIT CARD': 'CARTA DI CREDITO',
  'SHIPPING METHOD': 'METODO DI SPEDIZIONE',
  'EXPRESS COURIER': 'CORRIERE ESPRESSO',
  'DO YOU NEED HELP?': 'HAI BISOGNO DI AIUTO?',
  'WE ARE HERE TO ANSWER YOUR QUESTIONS. CALL US OR WRITE US VIA EMAIL!': 'SIAMO QUI PER RISPONDERE ALLE TUE DOMANDE. CHIAMACI O SCRIVICI VIA EMAIL!',
  'Cookie policy': 'Informativa cookie',
  'Cookie preferences': 'Preferenze cookie',
  'OUR CUSTOMER CARE IS AVAILABLE MONDAY THROUGH FRIDAY FROM 9 AM TO 1 PM AND FROM 2 PM TO 6 PM (CET).':
    'IL NOSTRO CUSTOMER CARE È A TUA DISPOSIZIONE DAL LUNEDÌ AL VENERDÌ DALLE 9:00 ALLE 13:00 E DALLE 14:00 ALLE 18:00 (CET)',
  'I HAVE READ AND UNDERSTOOD THE INFORMATION ON': 'HO LETTO E CAPITO L’ INFORMATIVA SULLA',
  'AND THE POLITICS ABOUT IT': 'E LA POLITICA SUI',
  'ALREADY REGISTERED?': 'GIÀ REGISTRATO?',
  'LOG IN HERE': 'ACCEDI QUI',
  'CREATE A NEW ACCOUNT': 'CREA UN NUOVO ACCOUNT',
  'CUSTOMER LOGIN': 'ACCESSO CLIENTE',
  'IF YOU HAVE AN ACCOUNT, SIGN IN WITH YOUR EMAIL ADDRESS.': 'SE HAI UN ACCOUNT, ACCEDI CON IL TUO INDIRIZZO EMAIL.',
  'No brands were found': 'Non è stato trovato alcun marchio',
  'discover other products': 'scopri altri prodotti',
  'Send application': 'Invia candidatura',
  'Select option': 'Seleziona opzione',
  'Availability:': 'Disponibilità:',
  'Experience:': 'Esperienza:',
  'Language:': 'Lingua:',
  'Read more': 'Leggi di più',
  'Read less': 'Leggi meno',
  'Apply now': 'Candidati ora',
  ADD: 'AGGIUNGI',
  'ADD TO CART': 'AGGIUNGI AL CARRELLO',
  'Order by': 'Ordina articoli per',
  'There are no sizes available for the selected product': 'Non ci sono taglie disponibili per il prodotto selezionato',
  'TO SEND THE ITEM BACK, USE THE DHL WAYBILL THAT FOUND IN THE PARCEL AND DELIVER IT TO THE NEAREST DHL POINT: ':
    'PER RISPEDIRE INDIETRO L’ARTICOLO UTILIZZA LA LETTERA DI VETTURA DHL CHE HAI TROVATO NEL PACCO E CONSEGNALO AL DHL POINT PIÙ VICINO: ',
  'NEW SIZE': 'NUOVA TAGLIA',
  'REQUESTED QUANTITY': 'QUANTITÀ RICHIESTA',
  'NO OTHER PRODUCTS SELECTABLE': 'NON CI SONO ALTRI PRODOTTI SELEZIONABILI',
  'An error occurred while loading the product': 'Si è verificato un errore durante il caricamento del prodotto',
  'REQUEST CHANGE': 'RICHIEDI CAMBIO',
  'Change created successfully': 'Richiesta di cambio creata con successo',
  'Unable to create change: please try later': 'Impossibile richiedere cambio: riprova più tardi',
  'QUANTITY TO CHANGE': 'QUANTITÀ DA CAMBIARE',
  'CHANGE REASON': 'MOTIVAZIONE DI CAMBIO',
  'MY CHANGES': 'I MIEI CAMBI',
  'YOU HAVE NOT YET REQUESTED RETURNS': 'NON HAI ANCORA RICHIESTO RESI',
  'YOU HAVE NOT YET REQUESTED CHANGES': 'NON HAI ANCORA RICHIESTO CAMBI',
  RETURNS: 'RESI',
  CHANGES: 'CAMBI',
  'In-store return': 'Reso in negozio',
  NAME: 'NOME',
  QUANTITY: 'QUANTITÀ',
  REASON: 'MOTIVAZIONE',
  'NEW COLLECTION': 'NUOVA COLLEZIONE',
  BRAND: 'MARCA',
  CATEGORIE: 'CATEGORIE',
  'NOTIFY ME': 'Avvisami',
  "Product doesn't have the requested quantity": 'Il prodotto non ha la quantità richiesta',
  'Coupon removed successfully': 'Coupon rimosso con successo',
  'Unable to remove coupon': 'Impossibile rimuovere il coupon',
  'Coupon applied successfully': 'Coupon applicato con successo',
  'Unable to apply coupon': 'Impossibile applicare il coupon',
  'MY WISHLIST': 'LA MIA WISHLIST',
  'Your wishlist is empty': 'La tua lista dei desideri è vuota',
  'Looks like you haven’t added any items to the Wishlist.': 'Sembra che tu non abbia aggiunto alcun articolo alla lista dei desideri.',
  'My wishlist': 'La mia lista dei desideri',
  'Go to Wishlist': 'Vai alla lista dei desideri',
  'Email if it’s back': 'Avvisami se in stock',
  DIRECTIONS: 'INDICAZIONI STRADALI',
  'GO TO CART': 'VAI AL CARRELLO',
  'The following items are no longer available for purchase, go back to the shopping cart and remove them to proceed to purchase: ':
    'I seguenti articoli non sono più disponibili all’acquisto, torna al carrello e rimuovili per procedere all’acquisto: ',
  'The following items are no longer available for purchase, remove them to proceed to purchase: ':
    'I seguenti articoli non sono più disponibili all’acquisto, rimuovili per procedere all’acquisto: ',
  size: 'TAGLIA IT',
  'YOU WILL BE ABLE TO VIEW THE SHIPPING TIME SEQUENCE SPECIFIC TO YOUR DESTINATION IN CHECKOUT.':
    'POTRAI VISUALIZZARE IL CALCOLO DEI COSTI DI SPEDIZIONE AL CHECKOUT.',
  'SCROLL DOWN TO EXPLORE': 'SCORRI VERSO IL BASSO PER ESPLORARE',
  'Send Request': 'Invia Richiesta',
  'Must be shorter than {0} characters': 'Può contenere al massimo {0} caratteri',
  'Must be longer than {0} characters': 'Deve contenere almeno {0} caratteri',
  'May only contain numeric characters': 'Può contenere solo caratteri numerici',
  'Notes field cannot contain the characters < and >': 'Il campo note non può contenere i caratteri < e >',
  'SUBSCRIBE TO OUR NEWSLETTER AND RECEIVE IMMEDIATELY 15% DISCOUNT ON YOUR ORDER AND A FREE DIVO BACKPACK!':
    'ISCRIVITI ALLA NOSTRA NEWSLETTER E RICEVI SUBITO IL 15% DI SCONTO SUL TUO ORDINE E LO ZAINO DIVO IN OMAGGIO!',
  'LET’S STAY IN TOUCH': 'RIMANIAMO IN CONTATTO',
  'Subscribe to the newsletter!': 'ISCRIVITI ALLA NEWSLETTER!',
  'DATE OF BIRTH': 'DATA DI NASCITA',
  'YOU CAN CANCEL YOUR SUBSCRIPTION AT ANY TIME. BY SIGNING UP YOU ACCEPT OUR':
    'PUOI ANNULLARE LA TUA ISCRIZIONE IN QUALSIASI MOMENTO. ISCRIVENDOTI ACCETTI I NOSTRI ',
  'IT’S OURS': 'E LA NOSTRA ',
  'Let’s keep in touch': 'Rimaniamo In Contatto',
  'REQUEST A CHANGE': 'RICHIEDI UN CAMBIO',
  'My Data': 'I miei dati',
  'My Addresses': 'I miei indirizzi',
  'Newsletter Subscription': 'Iscrizione newsletter',
  'My Orders': 'I miei ordini',
  'My Returns': 'I miei resi',
  'My Changes': 'I miei cambi',
  'My Wishlist': 'La mia wishlist',
  'my-returns': 'i-miei-resi',
  'RETURN REQUEST': 'RICHIESTA DI RESO',
  'SHIPPING ADDRESS': 'INDIRIZZO DI SPEDIZIONE',
  'REQUEST FOR EXCHANGES': 'RICHIESTE DI CAMBI',
  Province: 'Provincia',
  'DISCOVER MORE DSQUARED2 CLOTHING': 'SCOPRI PIÙ ABBIGLIAMENTO DI DSQUARED2',
  'DISCOVER MORE T-SHIRTS': 'SCOPRI PIÙ T-SHIRT',
  'DISCOVER MORE CLOTHING': 'SCOPRI PIÙ ABBIGLIAMENTO',
  'Or N.3 interest-free installments with': 'Oppure N.3 rate senza interessi con',
  INCLUDED: 'INCLUSA',
  'DO YOU HAVE A DISCOUNT CODE?': 'HAI UN CODICE SCONTO?',
  'DO YOU HAVE A GIFT CARD?': 'HAI UNA GIFT CARD?',
  'Opening Time': 'Orari',
  Category: 'Categoria',
  Subcategory: 'Sottocategoria',
  'See results': ' Vedi i risultati',
  'On Sale': 'In saldo',
  'Designer ID': 'Designer ID',
  Composition: 'Composizione',
  'Made in': 'Made in',
  'Size and Fit': 'Dimensioni e Vestibilità',
  'Street Name': 'Via',
  'Pending Payment': 'In attesa di Pagamento',
  Processing: 'in lavorazione',
  Open: 'Aprire',
  Pending: 'In attesa di',
  Confirmed: 'Confermato',
  Shipped: 'Spedito',
  Complete: 'Completato',
  Cancelled: 'Annullato',
  Refunded: 'Rimborsato',
  'SELECT THE ITEMS TO RETURN': ' SELEZIONA GLI ARTICOLI DA RENDERE',
  'Already returned': 'Già restituito',
  'On sale': 'Promo e saldi',
  'Price from high to low': 'Prezzo dal più alto',
  'Price from low to high': 'Prezzo dal più basso',
  'Unfortunately this size is finished': 'Purtroppo questa taglia è terminata',
  'Request a size': 'Richiedi una taglia',
  "Let me know when it's back in stock!": 'Avvisami quando torna in stock!',
  "Let me know when it's available!": 'Avvisami quando sarà disponibile!',
  'You will be notified when the size you want is available': 'Sarai avvisato quando la taglia che desideri sarà disponibile',
  'Required size': 'Taglia richiesta',
  'Required size *': 'Taglia richiesta *',
  'You will be notified when the size you want is back in stock': 'Sarai avvisato quando la taglia che desideri tornerà in stock',
  'New arrivals': 'Nuovi arrivi',
  'OUR CUSTOMER CARE IS AT YOUR DISPOSAL FROM ': 'IL NOSTRO CUSTOMER CARE È A TUA DISPOSIZIONE DAL ',
  MONDAY: 'LUNEDÌ',
  ' TO ': ' AL ',
  ' TO': ' DALLE',
  FRIDAY: 'VENERDÌ',
  ' FROM ': ' DALLE ',
  ' AND FROM ': ' E DALLE ',
  'YOU CAN CONTACT US BY FILLING IN THE FORM BELOW. OUR STAFF WILL GET BACK TO YOU AS SOON AS POSSIBLE.':
    'PUOI CONTATTARCI COMPILANDO IL FORM QUI SOTTO. IL NOSTRO STAFF TI RISPONDERÀ AL PIÙ PRESTO.',
  'OUR BOUTIQUES': 'LE NOSTRE BOUTIQUE',
  TIMETABLES: 'ORARI',
  'MONDAY: 16-20 | TUESDAY-SATURDAY: 9-13 AND 16-20': 'LUNEDÌ: 16-20 | MARTEDÌ-SABATO: 9-13 E 16-20',
  'OPEN ON THE SECOND SUNDAY OF EACH MONTH': 'APERTI LA SECONDA DOMENICA DI OGNI MESE',
  'AS THE PERSON CONCERNED, HAVING READ THE INFORMATION ON THE': 'IN QUALITÀ DI INTERESSATO, AVENDO LETTO L’INFORMATIVA SULLA',
  ', I EXPRESSLY CONSENT TO THE PROCESSING OF MY PERSONAL DATA IN ORDER TO RESPOND TO MY REQUEST FOR INFORMATION FROM DIVO BOUTIQUE':
    ', ACCONSENTO ESPRESSAMENTE AL TRATTAMENTO DEI MIEI DATI PERSONALI PER RISPONDERE ALLA MIA RICHIESTA DI INFORMAZIONI DA PARTE DI DIVO BOUTIQUE',
  Carryover: 'Continuativo',
  'Address could not be saved!': "Non è stato possibile salvare l'indirizzo!",
  AI: 'FW',
  PE: 'SS',
  Season: 'Stagione',
  'You need informations about:': 'Preferisci ricevere informazioni su:',
  'BASE GRAND TOTAL': 'TOTALE BASE',
  'Base grand total': 'TOTALE BASE',
  'TOTAL SHIPPING': 'SPEDIZIONE TOTALE',
  'Total shipping': 'SPEDIZIONE TOTALE',
  'Send inquiry': 'Invia richiesta',
  'Do you want to return the product in one of our stores?': 'Vuoi fare il reso in negozio?',
  'Which of our shops would you like to return the product to?': 'In quale dei nostri negozi vuoi restituire il prodotto?',
  ' and to receive personalized commercial emails from us.': ' e di ricevere informazioni commerciali personalizzate dal marchio via e-mail.',
  'I changed my mind': 'Ho cambiato idea',
  'Wrong Size': 'Taglia sbagliata',
  'Product does not match the description': 'Il prodotto non corrisponde alla descrizione',
  'Damaged Item': 'Articolo danneggiato',
  'Color does not meet my expectations': 'Il colore non corrisponde alle mie aspettative',
  'Too tight': 'Troppo stretto',
  'Too loose': 'Troppo largo',
  Other: 'Altro',
  'An user with this email already exists.': 'Un utente con questa email esiste già.',
  'An error occurred while registering. Please contact our customer support.':
    'Si è verificato un errore durante la registrazione. Contatta il nostro servizio clienti.',
  'This cart is not eligible for coupon usage.': "Questo carrello non è idoneo per l'utilizzo di coupon.",
  'SEARCH DESIGNER': 'CERCA DESIGNER',
  'DISCOVER MORE': 'SCOPRI DI PIÙ',
  'Return created successfully.': 'Reso creato con successo',
  'Unable to create return: please try later.': 'Impossibile creare un reso: riprovare più tardi.',
  'THIS FIELD IS NOT CORRECT.': 'QUESTO CAMPO NON È CORRETTO.',
  'this field is not correct.': 'questo campo non è corretto.',
  'Unable to select address: invalid country': 'Unable to select address: invalid country',
  'PRODOTTI ACQUISTATI': 'PRODOTTI ACQUISTATI',
  UNKNOWN: 'SCONOSCIUTO',
  PENDING: 'IN ATTESA',
  FAILED: 'FALLITO',
  CREATED: 'CREATO',
  CANCELED: 'ANNULLATO',
  REJECTED: 'RIFIUTATO',
  APPROVED: 'APPROVATO',
  WAITING_ITEMS: 'ARTICOLI IN ATTESA',
  ITEMS_RECEIVED: 'ARTICOLI RICEVUTI',
  ITEMS_VERIFIED: 'ARTICOLI VERIFICATI',
  COMPLETED: 'RIMBORSATO',
  of: 'di',
  products: 'prodotti',
  'Loading...': 'Caricamento...',
  'Show previous page': 'Mostra pagina precedente',
  'Show more results': 'Mostra più risultati',
  'Terms and conditions': 'Termini e condizioni',
  'I have read and agree to the ': 'Ho letto e accetto la',
  qui: 'qui',
  Canceled: 'Annullato',
  Refund: 'Rimborsato',
  'Return reason': 'Motivazione reso',
  ' with the current filters.': ' con i filtri attuali.',
  'We found no results matching your filters, please reset them.': 'Non abbiamo trovato risultati corrispondenti ai tuoi filtri, prova ad azzerarli.',
  'Discover more': 'Scopri più',
  'Republic of China': 'cina',
  'Order Number': "Numero d'ordine",
  'Item Name And Size': 'Nome e Taglia articolo',
  'An error occurred while sending your request, please contact us directly at customercare@divoboutique.com':
    "Si è verificato un errore durante l'invio della richiesta, vi preghiamo di contattarci direttamente all'indirizzo customercare@divoboutique.com.",
  'Manage your Return': 'Gestisci il tuo reso',
  'To start a return please enter your data below': 'Per avviare un reso, inserire i propri dati qui di seguito',
  'Which items do you want to return?': 'Quali articoli vuoi rendere?',
  'Discount percentage': 'Percentuale sconto',
  'Not discounted': 'Non scontato',
  'A new version of the website is available. Please refresh the page to update.':
    'È disponibile una nuova versione del sito. Ricarica la pagina per aggiornare.',
  'Refresh to update': 'Ricarica per aggiornare',
  REFRESH: 'RICARICA',
  pickUpInStoreSelectStore: 'SELEZIONA IL NEGOZIO',
  pickUpInStoreWarningTextFirstLine: 'N.B. PRIMA DI RECARTI IN NEGOZIO, ATTENDI LA NOSTRA MAIL CHE TI INFORMA CHE IL PACCO È PRONTO PER IL RITIRO.',
  pickUpInStoreWarningTextSecondLine: 'IL RITIRO NON SARÀ POSSIBILE DI SABATO E DOMENICA E NEI GIORNI FESTIVI.',
  'New Season': 'Nuova Stagione',
  'Go back to your Shopping Bag': 'Torna alla Shopping Bag',
  'Contact us: customercare@divoboutique.com': 'Contattaci: customercare@divoboutique.com',
  'Complete the shipping step to show the correct amount': "Completa lo step di spedizione per calcolare l'importo corretto",
  'Default Shipping': 'Spedizione predefinita',
  'Scalapay is not available for orders over 1500€. Please choose a different payment method.':
    'Scalapay non è disponibile per ordini superiori a 1500€. Scegli un metodo di pagamento diverso.',
  'Make delivery easier by indicating the intercom or other useful details':
    'Facilita il corriere nella consegna indicando il citofono o altri dettagli utili',
  'Required fields': 'Questi campi sono obbligatori',
  'Start typing your address': 'Inizia a digitare il tuo indirizzo',
  'All products have been returned': 'Tutti i prodotti sono stati restituiti',
  "We apologize, but it's not possible to request a return for this order": 'Siamo spiacenti, non è possibile richiedere un reso per questo ordine',
  'Store pickup': 'Ritiro in negozio',
  'DHL express': 'DHL express',
  'YOU WILL BE ABLE TO ACCESS EXCLUSIVE PROMOS, MANAGE YOUR ORDERS, SAVE YOUR WISHLIST AND MUCH MORE.':
    'POTRAI ACCEDERE A PROMO ESCLUSIVE, GESTIRE I TUOI ORDINI, SALVARE LA TUA WISHLIST E MOLTO ALTRO.',
  'Accedi al tuo account per salvare la wishlist': 'Accedi al tuo account per salvare la wishlist',
  'Select the size first': 'Seleziona prima la taglia',
  'Free shipping over ': 'Spedizione gratuita oltre ',
  'New Arrivals': 'Nuova Stagione',
  'Latest research:': 'Ricerche recenti:',
  'Your results for': 'I tuoi risultati per',
  men: 'uomo',
  women: 'donna',
  kids: 'kids',
  close: 'chiudi',
  "We're sorry, but there are not results for": 'Ci dispiace, ma non abbiamo trovato nessun risultato per',
  "We're sorry, but there are not results for your search": 'Ci dispiace, ma non abbiamo trovato nessun risultato per la tua ricerca',
  'Most popular': 'Più popolari',
  'Sorry, the time for return/exchange has expired': 'Il tempo per il reso/cambio è scaduto',
  'Duties and taxes excluded': 'Dazi & tasse escluse',
  'Thank you for subscribing to our newsletter!': 'Grazie per esserti iscritto alla nostra newsletter!',
  'You will shortly receive a welcome email, with a special gift for you!': 'Riceverai a breve una mail di benvenuto, con un regalo speciale per te!',
  'Manage your Return/Change': 'Gestisci il tuo reso/cambio',
  'To start a return or make a product change please enter your data below':
    'Per avviare un reso o effettuare un cambio, inserire i propri dati qui di seguito',
  'Which items do you want to return/change (indicate the size)?': 'Quali articoli vuoi rendere o cambiare (indica taglia)?',
  'Items purchased between December 3 and December 25, 2024, are eligible for returns until January 15, 2025.':
    'Gli articoli acquistati tra il 3 dicembre e il 25 dicembre 2024 potranno essere resi e cambiati fino al 15 gennaio 2025.',
  'Order by December 16th for Christmas delivery': 'Ordina entro il 16 dicembre per riceverlo prima di Natale',
  'Christmas delivery is no longer guaranteed': 'Non è più garantita la consegna entro Natale',
};
