<template>
  <div
    v-if="type === 'inline-cta' || type === 'icon'"
    class="custom-label"
    :class="[
      { 'custom-label__disabled': disabled },
      { 'custom-label__full-width': isFullWidth },
    ]"
    :style="!!forceWidth ? { width: forceWidth } : {}"
    @click="handleClick"
  >
    <SfLink
      v-if="link"
      :link="link.includes('http') ? link : localePath(link)"
      :class="`custom-label__label `"
    >
      <slot />
    </SfLink>
    <span v-else :class="`custom-label__label`">
      <slot />
    </span>
  </div>
  <SfButton
    v-else
    :disabled="disabled"
    :type="type"
    :link="link.includes('http') ? link : localePath(link).replace('%2F', '/')"
    :aria-label="ariaLabel"
    :class="[
      `custom-button ${layout}-layout-type`,
      { 'custom-button__disabled': disabled },
      { 'custom-button__full-width': isFullWidth },
    ]"
    :style="!!forceWidth ? { width: forceWidth } : {}"
    @click="handleClick"
  >
    <component v-if="hasIcon" :is="iconComponent" />
    <slot />
  </SfButton>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfButton, SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'CustomButton',
  components: {
    SfLink,
    SfButton,
  },
  props: {
    layout: {
      type: String,
      default: 'black',
      validator(value) {
        return ['black', 'white', 'blurred', ''].includes(value);
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'button',
      validator: (value) =>
        ['button', 'submit', 'reset', 'inline-cta', 'icon'].includes(value),
    },
    link: {
      type: String,
      default: '',
    },
    ariaLabel: {
      type: String,
      default: 'button',
    },
    isFullWidth: {
      type: Boolean,
      default: false,
    },
    iconComponent: {
      type: String,
      default: '',
    },
    forceWidth: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const handleClick = () => {
      if (!props.disabled && props.link === '') emit('click');
    };
    const hasIcon = computed(() => !!props.iconComponent);
    return { handleClick, hasIcon };
  },
});
</script>

<style lang="scss" scoped>
.custom-label {
  width: fit-content;
  display: flex;
  align-items: center;
  &__label {
    display: contents;
    cursor: pointer;
    @include inline-cta;
    text-transform: uppercase;
    @include label-hover(var(--barlow-medium));
    color: var(--c-dark-grey);
  }
  &__disabled {
    cursor: auto;
    pointer-events: none;
    .custom-label__label {
      color: var(--c-dark-grey);
    }
    .custom-label__icon path {
      stroke: var(--c-dark-grey);
    }
  }
  &__full-width {
    width: 100%;
    min-width: 7.5rem;
  }
}
.custom-button {
  text-decoration: none;
  border: var(--general-border);
  &.black-layout-type {
    --button-color: var(--c-white);
    --button-background: var(--c-black);
    @include link-regular;
    @include button-hover(var(--c-black-lighten));
  }
  &.white-layout-type {
    --button-color: var(--c-black);
    --button-background: var(--c-white);
    @include link-regular;
    @include button-hover(var(--c-black));
  }
  &.blurred-layout-type {
    --button-color: var(--c-white);
    border: var(--button-transparent-border);
    opacity: 1;
    --button-background: var(--c-semi-transparent-black);
    backdrop-filter: blur(0.125rem);
    @include link-regular;
    @include button-hover(var(--c-black));
    @include hover-reset-border;
  }
  &__full-width {
    width: 100%;
    max-width: 31.25rem;
  }
  &__disabled {
    cursor: auto;
    pointer-events: none;
    --button-background: var(--c-semi-transparent-black) !important;
    --button-color: var(--c-white) !important;
  }
}
</style>
